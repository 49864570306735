body {
  background-color: #afb5c7;
  font-family: Helvetica, Arial, sans-serif;
}

.navbar {
  background-color: #0055bf;
  width: 100%;
  padding-top: 1px;
  padding-bottom: 1px;
}

.App {
  text-align: center;
}

.navlink {
  margin: 7px;
  color: #ffffff;
}

.brandLink,
a.brandLink {
  color: #b4d2e3;
}

button {
  background-color: #0a3463;
  color: #ffffff;
  border: none;
  height: 2em;
  width: 4em;
}

a {
  color: #0055bf;
}

a.navlink {
  color: white;
}

.comicImg {
  max-width: 95%;
  max-height: 500px;
  border: black solid 1px;
}

.comicImgExpanded {
  max-height: 95vh;
  max-width: 95%;
  border: black solid 1px;
}

.navButton {
  width: 70px;
  margin: 2px;
  margin-top: 5px;
  text-align: center;
}

.navRow {
  width: 420px;
  max-width: 100%;
  margin: auto;
}

.navCol {
  margin-right: 0px;
  margin-left: 0px;
}

.aboutText {
  text-align: left;
}

.container {
  max-width: 800px;
  margin: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 5px;
  border: black solid 1px;
}

p,
li {
  text-align: left;
  padding-right: 5px;
  padding-left: 5px;
}

.copyright {
  margin-top: 20px;
}

li {
  list-style: none;
}

.otherComics {
  padding-left: 20px;
}

.pageNotFound-img {
  width: 350px;
  border: black solid 1px;
}

.aprilFoolsImg {
  width: 350px;
  border: black solid 1px;
}

.aprilFools {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
}

.horizontalLogo {
  width: 170px;
}

.donate {
  background-color: yellow;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 30em;
  max-width: 90%;
  border: 1px black solid;
}

.leaveMeAlone {
  color: #0055bf;
  cursor: pointer;
}

.faqQ {
  text-align: left;
  padding-right: 5px;
  padding-left: 5px;
}

.hidden {
  display: none;
}

.blogPost {
  border: solid black 1px;
  max-width: 800px;
  margin: auto;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 5px;
  border: black solid 1px;
}

td {
  text-align: left;
  padding-left: 10px;
}

.blogImg {
  max-width: 95%;
}

.nftImg {
  width: 100%;
}
.nftButton {
  width: 8rem;
}
.nftButton:disabled {
  background-color: gray;
}
.text-input {
  width: 100%;
}
.text-input-label {
  margin-bottom: 2px;
}
.text-input-box {
  width: 100%;
}
.nft-payment {
  margin: auto;
}
.boolean-input {
  width: 100%
}
